import React, { useEffect, useState } from 'react';
import useUserResponseDetailService from '../../../app/services/hooks/useUserResponseDetail';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { UserResponseDetail } from '../../../app/models/UserResponseDetail';
import { UserResponseOption } from '../../../app/models/UserResponseOption';
import { UserResponseFile } from '../../../app/models/UserResponseFile';
import { InputResponseType } from '../../../app/shared/enums';
import { momentParseDate, momentParseDateTime } from '../../../helpers';
import { formatNumber } from '../../../utils/utils';
import { QuestionDataForm } from '../../../app/models/Question';
import FileCarrousel from './FileCarrousel';

interface Props {
    responses: any[];
    isConfimation?: boolean;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UserResponseconfirm = ({ 
    responses,
    isConfimation=false, 
    onSaved, 
    onCancel, 
    onError 
}: Props) => {
    const [ responseOptions, setResponseOptions ] = useState<UserResponseOption[]>([])
    const [ responseFiles, setResponseFiles ] = useState<QuestionDataForm[]>([])

    useEffect(()=>{

        const responsesWithImages = responses.filter(
            res => res.selectedImages && res.selectedImages.length > 0
        );

        setResponseFiles(responsesWithImages);
    },[responses])
  
    const getOptionsForResponseDetail = (detailId: number) => {
        return responseOptions.filter(option => option.user_response_detail_id === detailId);
    };

    return (
        <>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Pregunta</th>
                            <th scope="col">Respuesta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {responses.map((res, index) => {
                            const files = responseFiles.some(item => item.id === res.id);

                            if (files){
                                return (
                                    <>
                                        <tr key={res.id}>
                                            <th scope="row" className="align-top">{index + 1}</th>
                                            <td className="align-top text-center">{res.question_text}</td>
                                            <td className="align-top text-center"></td>
                                        </tr>
                                        <tr key={res.id}>
                                            <th scope="row" className="align-top"></th>
                                            <td className="align-top text-center"><FileCarrousel imageURLs={res.images} /></td>
                                            <td className="align-top text-center"></td>
                                        </tr>
                                    </>
                                )
                            }else {
                                return (
                                    <tr key={res.id}>
                                        <th scope="row" className="align-top">{index + 1}</th>
                                        <td className="align-top">{res.question_text}</td>
                                        <td className="align-top">
                                            {res.text ? (
                                                <p>
                                                {res.response_type === InputResponseType.DATETIME
                                                    ? momentParseDateTime(res.text)
                                                    : res.response_type === InputResponseType.DATE
                                                    ? momentParseDate(res.text)
                                                    : res.response_type === InputResponseType.NUMBER
                                                    ? !isNaN(Number(res.text))  
                                                        ? formatNumber(Number(res.text))
                                                        : ''
                                                    : res.text
                                                }
                                                
                                                </p>
                                            ) : (
                                                <p>
                                                    {isConfimation 
                                                        ? res.options
                                                            .filter((option: { is_response: any; }) => option.is_response) 
                                                            .map((option: { text: any; }) => option.text) 
                                                            .join(', ')
                                                        : getOptionsForResponseDetail(res.id)
                                                            .filter(option => option.is_response) 
                                                            .map(option => option.text) 
                                                            .join(', ')
                                                    }
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                )
                            }}
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UserResponseconfirm;
