import React, { useState, useContext } from 'react';
import { ExpenseFilters } from '../../../../app/models/Expense';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';
import MultiSelect from '../../../../components/MultiSelect';
import { ExpenseType } from '../../../../app/models/ExpenseType';
import { AuthContext } from '../../../../contexts/AuthContext';

type Props = {
    filter: ExpenseFilters;
    showTransportOrder: boolean;
    setFilter: (filter: ExpenseFilters) => void;
    onSearch: () => void;
    expenseTypeData: ExpenseType[];
};

const ExpenseFilter = ({ filter, setFilter, showTransportOrder, onSearch, expenseTypeData }: Props) => {
    const { auth } = useContext(AuthContext); // Contexto de permisos
    const [selectedExpenseTypes, setSelectedExpenseTypes] = useState<string[]>(
        filter.expense_type ? filter.expense_type.split(',') : []
    );

    const expenseTypeOptions = expenseTypeData
        .filter((type) => type.name) // Filtrar tipos válidos
        .map((type) => ({
            value: type.name as string,
            label: type.name as string,
        }));

    const handleExpenseTypeChange = (selectedValues: string[]) => {
        setSelectedExpenseTypes(selectedValues);
        if (selectedValues.length > 0) {
            setFilter({ ...filter, expense_type: selectedValues.join(',') });
        } else {
            const { expense_type, ...updatedFilter } = filter;
            setFilter(updatedFilter); // Remover el campo si no hay selección
        }
    };

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };


    return (
        <div className="row">
            <div className="row">
                {showTransportOrder && (
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="transport_order_id">
                                Orden de transporte
                            </label>
                            <input
                                title="Orden de transporte"
                                type="number"
                                id="transport_order_id"
                                name="transport_order_id"
                                className="form-control form-control-sm"
                                value={filter.transport_order_id ?? ''}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                )}
                {(auth?.hasAllPermissions || auth?.roles?.includes('Administrador')) && (
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="carrier">
                                Conductor
                            </label>
                            <input
                                title="Conductor"
                                type="text"
                                id="carrier"
                                name="carrier"
                                className="form-control form-control-sm"
                                value={filter.carrier ?? ''}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                )}
                <div className="col-md-auto">
                    <MultiSelect
                        label="Tipo de gasto"
                        value={selectedExpenseTypes}
                        onChange={handleExpenseTypeChange}
                        options={expenseTypeOptions} // Opciones transformadas
                    />
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="provider">
                            Proveedor
                        </label>
                        <input
                            title="Proveedor"
                            type="text"
                            id="provider"
                            name="provider"
                            className="form-control form-control-sm"
                            value={filter.provider ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="expense_document_type">
                            Tipo de documento
                        </label>
                        <select
                            id="expense_document_type"
                            name="expense_document_type"
                            className="form-control form-select-sm"
                            value={filter.expense_document_type ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'BOLETA'} value="BOLETA">
                                Boleta
                            </option>
                            <option key={'FACTURA'} value="FACTURA">
                                Factura
                            </option>
                        </select>
                    </div>
                </div>
                {showTransportOrder && (
                <div className="row">
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="issue_date_first">
                                Fecha de emisión (desde)
                            </label>
                            <input
                                title="Fecha de emisión"
                                type="date"
                                id="issue_date_first"
                                name="issue_date_first"
                                className="form-control form-control-sm"
                                value={filter.issue_date_first}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="issue_date_last">
                                Fecha de emisión (hasta)
                            </label>
                            <input
                                title="Fecha de emisión"
                                type="date"
                                id="issue_date_last"
                                name="issue_date_last"
                                className="form-control form-control-sm"
                                value={filter.issue_date_last}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                </div>
            )}
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpenseFilter;
