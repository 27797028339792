import { useEffect, ChangeEvent } from 'react';
import { DocumentDataForm } from '../../../../app/models/Document';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { setCleanTextAreaError } from '../../../../utils/utils';

interface Props {
    documentForm: DocumentDataForm;
    setDocumentForm: (document: DocumentDataForm) => void;
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const DocumentForm = ({ documentForm, setDocumentForm, errorFields, setErrorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setDocumentForm({ ...documentForm, [name]: value });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="document_name"
                        id="document_name"
                        type="text"
                        className={`form-control ${fieldHasError('document_name')}`}
                        value={documentForm.document_name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('document_name')}
                        placeholder="Ingrese el nombre del Documento"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('document_name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Descripción</label>
                <div className="col-md-9">
                    <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={documentForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                        // disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DocumentForm;
