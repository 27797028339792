import React, { useEffect, useRef, useState } from 'react';

type Option = {
    value: string;
    label: string;
};

type MultiSelectProps = {
    label: string;
    value: string[]; // Valores seleccionados
    onChange: (selectedValues: string[]) => void; // Callback para notificar cambios al padre
    options: Option[]; // Opciones proporcionadas desde el backend
};

const MultiSelect: React.FC<MultiSelectProps> = ({ label, value, onChange, options }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false); // Controla si el dropdown está abierto
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Manejo de clics fuera del dropdown
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleToggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleOptionChange = (selectedValue: string) => {
        let updatedValues;

        if (selectedValue === 'ALL') {
            // Manejo de "Seleccionar todo"
            const allSelected = value.length === options.length;
            updatedValues = allSelected ? [] : options.map((opt) => opt.value);
        } else {
            // Selección/desmarcado individual
            updatedValues = value.includes(selectedValue)
                ? value.filter((item) => item !== selectedValue)
                : [...value, selectedValue];
        }

        onChange(updatedValues); // Notifica al padre sobre los cambios
    };

    return (
        <div className="mb-3" ref={dropdownRef}>
            <label className="font-size-10 mb-1">{label}</label>
            <div className="dropdown">
                <button
                    className="form-control form-control-sm"
                    type="button"
                    onClick={handleToggleDropdown}
                    style={{
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {value.length > 0 ? `${value.length} seleccionados` : `Seleccione ${label.toLowerCase()}`}
                </button>
                {dropdownOpen && (
                    <div
                        className="dropdown-menu show p-2"
                        style={{
                            position: 'absolute',
                            width: '100%',
                            maxHeight: '200px',
                            overflowY: 'auto',
                            zIndex: 10,
                        }}
                    >
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="select-all"
                                checked={value.length === options.length}
                                onChange={() => handleOptionChange('ALL')}
                            />
                            <label className="form-check-label" htmlFor="select-all">
                                Seleccionar todo
                            </label>
                        </div>
                        {options.map((option) => (
                            <div className="form-check" key={option.value}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`option-${option.value}`}
                                    checked={value.includes(option.value)}
                                    onChange={() => handleOptionChange(option.value)}
                                />
                                <label className="form-check-label" htmlFor={`option-${option.value}`}>
                                    {option.label}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MultiSelect;
