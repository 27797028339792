import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import AIDataTable from './components/tables/AIDataTable';
import AIFilter from './components/tables/AIFilter';
import useAITolls from '../../app/services/hooks/useAITolls';
import { AppContext } from '../../contexts/AppContext';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import { AITollFilters } from '../../app/models/AITolls';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'APIs',
        url: '/ai-tolls',
        isActive: true,
    },
];

const AITolls = () => {
    const { getCountPendingTollExpenses, getAllPendingTollExpenses, getPendingTollExpenses } = useAITolls();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { successAlert, errorAlert } = useReactConfirmAlert();

    const [pendingTolls, setPendingTolls] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isReviewing, setIsReviewing] = useState(false);

    const [filters, setFilters] = useState<AITollFilters>({
        id: null,
        transport_order: '',
        start_date: null,
        end_date: null,
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc',
    });

    // Carga inicial de tabla y conteo
    useEffect(() => {
        loadInitialData();
    }, []);

    // Recargar tabla y conteo cada vez que cambien los parámetros de paginación y ordenación
    useEffect(() => {
        reloadTable();
        loadPendingCount();
    }, [filters.page, filters.per_page, filters.sort, filters.order]);

    const loadInitialData = () => {
        reloadTable();
        loadPendingCount();
    };

    const reloadTable = () => {
        setIsTableLoading(true);
        getAllPendingTollExpenses(filters, {
            onSuccess: (response: ServiceResponse) => {
                setPendingTolls(response.data?.pending_tolls || []);
                setTotalRows(response.data?.total_rows || 0);
                setIsTableLoading(false);
            },
            onError: () => {
                toast.error('Error al cargar la tabla de peajes pendientes.');
                setIsTableLoading(false);
            },
        });
    };

    const loadPendingCount = () => {
        getCountPendingTollExpenses(filters, {
            onSuccess: (response: ServiceResponse) => {
                setPendingCount(response.data?.count_pending_expenses || 0);
            },
            onError: () => {
                toast.error('Error al cargar el conteo de peajes pendientes.');
            },
        });
    };

    const handleFilterSearch = () => {
        console.log('Filtros enviados:', filters);
        reloadTable();
        loadPendingCount();
    };

    const handleReviewTolls = () => {
        setIsReviewing(true);
        if (showLoading) showLoading('loading', 'Revisando Peajes...');

        getPendingTollExpenses(filters, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                successAlert({
                    title: 'Éxito',
                    message: response.message || 'Revisión de peajes completada.',
                });
                toast.success(response.message || 'Revisión de peajes completada.', { autoClose: 2500 });
                setIsReviewing(false);
                loadInitialData();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                errorAlert({
                    title: 'Error',
                    message: response.message || 'Error al procesar la revisión de peajes.',
                });
                setIsReviewing(false);
            },
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="APIs" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <h3 style={{ marginBottom: '20px' }}>API de Revisión de Peajes Pendientes</h3>

                <AIFilter filter={filters} setFilter={setFilters} onSearch={handleFilterSearch} />

                <div className="mt-4">
                    <p className="mt-3 fw-bold">{`Peajes para Revisión: ${pendingCount}`}</p>
                </div>

                <div className="mt-4">
                    <button
                        style={{
                            padding: '10px 20px',
                            backgroundColor: isReviewing ? '#e8f5f2' : '#7CE2C3',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            fontSize: '16px',
                            cursor: isReviewing ? 'not-allowed' : 'pointer',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            transition: '0.3s ease',
                            marginBottom: '10px',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                        onClick={isReviewing ? undefined : handleReviewTolls}
                        disabled={isReviewing}
                    >
                        {isReviewing ? 'Revisando...' : 'Revisar Peajes'}
                    </button>
                </div>

                <div className="mt-4">
                    <AIDataTable
                        pendingTolls={pendingTolls}
                        totalRows={totalRows}
                        loading={isTableLoading}
                        filter={filters}
                        setFilter={setFilters}
                        callbackSuccess={reloadTable}
                        onSearch={handleFilterSearch}
                    />
                </div>
            </DefaultCard>
        </>
    );
};

export default AITolls;
