import { toast } from 'react-toastify';
import { getFormData, ResponseStatus } from '../app/services/ApiHelper';
import {
    ServiceParams,
    ServiceParamsDocuments,
    ServiceResponse
} from '../app/services/shared/interfaces';
import { getFetchDefaultHeaders } from '../app/services/shared/vars';
import { LOCAL_STORAGE } from '../config';

const useFetch = () => {
    const doGet = (serviceParams: ServiceParams) => {
        abstractFetch('GET', serviceParams);
    };

    const doPost = (serviceParams: ServiceParams) => {
        abstractFetch('POST', serviceParams);
    };

    const doPut = (serviceParams: ServiceParams) => {
        abstractFetch('PUT', serviceParams);
    };

    const doDelete = (serviceParams: ServiceParams) => {
        abstractFetch('DELETE', serviceParams);
    };

    const doGetDocument = (serviceParams: ServiceParamsDocuments) => {
        abstractFetchDocument('GET', serviceParams);
    };


    const abstractFetch = (method: string, serviceParams: ServiceParams) => {
        const {
            url,
            body,
            headers,
            requestType,
            onSuccess,
            onError,
            onInfo,
            onWarning,
            onFieldError,
            setFetching
        } = serviceParams;

        let httpStatus = 0;

        if (setFetching) {
            setFetching(true);
        }

        const fetchDefaultHeaders = getFetchDefaultHeaders();
        fetch(url, {
            method: method,
            headers: { ...fetchDefaultHeaders, ...headers },
            body: body ? (requestType == 'json' ? JSON.stringify(body) : getFormData(body)) : null
        })
            .then((response) => {
                httpStatus = response.status;
                // console.log(response.headers.get("Content-Type"))
                return response.json();
            })
            .then((response) => {
                if (
                    httpStatus === 401 &&
                    window.location.href != `${window.location.origin}/login`
                ) {
                    localStorage.removeItem(LOCAL_STORAGE.AUTH);
                    localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);

                    window.location.href = '/login';
                }

                response.http_status = httpStatus;

                handleResponse(response, onSuccess, onError, onInfo, onWarning, onFieldError);
                if (setFetching) {
                    setFetching(false);
                }
            })
            .catch((e) => {
                // console.log(e);
                if (setFetching) {
                    setFetching(false);
                }
            });
    };

    const abstractFetchDocument = (method: 'GET', serviceParams: ServiceParamsDocuments) => {
        const {
            url,
            headers,
            onSuccess,
            onError,
            onInfo,
            onWarning,
            onFieldError,
            setFetching,
            successData = {
                nameDocument: 'document',
                message: 'Documento recuperado con éxito'
            }
        } = serviceParams;

        let httpStatus = 0;

        if (setFetching) {
            setFetching(true);
        }

        const fetchDefaultHeaders = getFetchDefaultHeaders();

        fetch(url, {
            method: method,
            headers: { ...fetchDefaultHeaders, ...headers }
        })
            .then((response) => {
                httpStatus = response.status;

                if (response.ok) {
                    return response.blob();
                }
                return response.json();
            })
            .then((data) => {
                if (
                    httpStatus === 401 &&
                    window.location.href != `${window.location.origin}/login`
                ) {
                    localStorage.removeItem(LOCAL_STORAGE.AUTH);
                    localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);

                    window.location.href = '/login';
                }

                if (httpStatus === 200) {
                    const blobURL = URL.createObjectURL(data);
                    const a = document.createElement('a');
                    a.href = blobURL;
                    a.download = successData.nameDocument;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    URL.revokeObjectURL(blobURL);

                    if (onSuccess) onSuccess(successData.message);
                } else {
                    if (onError) onError(data);
                }

                if (setFetching) {
                    setFetching(false);
                }
            })
            .catch((e) => {
                console.log(e);
                if (setFetching) {
                    setFetching(false);
                }
            });
    };


    const handleResponse = (
        response: ServiceResponse,
        onSuccess?: (response: ServiceResponse) => void,
        onError?: (response: ServiceResponse) => void,
        onInfo?: (response: ServiceResponse) => void,
        onWarning?: (response: ServiceResponse) => void,
        onFieldError?: (response: ServiceResponse) => void
    ) => {
        switch (response.status) {
            case ResponseStatus.SUCCESS:
                if (onSuccess) {
                    onSuccess(response);
                }
                break;
            case ResponseStatus.ERROR:
                if (onError) {
                    onError(response);
                }
                break;
            case ResponseStatus.INFO:
                if (onInfo) {
                    onInfo(response);
                }
                break;
            case ResponseStatus.WARNING:
                if (onWarning) {
                    onWarning(response);
                }
                break;
            case ResponseStatus.FIELDS_VALIDATION:
                if (onFieldError) {
                    onFieldError(response);
                }
                break;
            default:
                if (onError) {
                    onError(response);
                }
                break;
        }
    };

    return {
        doGet,
        doPost,
        doPut,
        doDelete,
        doGetDocument,
    };
};

export default useFetch;
