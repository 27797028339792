import useFetch from '../../../hooks/useFetch';
import { useContext, useState } from 'react';
import { CarrierDocument } from '../../models/Carrier';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { TruckDocument } from '../../models/Truck';
import { DocumentNameFilters } from '../../models/Document';
import { fetchDefaultHeaders } from '../shared/vars';
import { List } from 'echarts';
import { Service } from '../../models/Service';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import useResponseOptionService from './useResponseOptionService';


    const useTruckDocumentService = () => {
        const { doGet, doPost } = useFetch();

        const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);


        const [
            fetchingGetTruckDocumentsByTruckAndDocumentType,
            setFetchingGetTruckDocumentsByTruckAndDocumentType
        ] = useState(false);
        const [fetchingGetTruckDocuments, setFetchingGetTruckDocuments] = useState(false);
        const [fetchingStoreTruckDocument, setFetchingStoreTruckDocument] = useState(false);
        const [fetchingGetTruckDocumentsByset, setFetchingGetTruckDocumentBySet] = useState(false);

        const getTruckDocumentsByTruck = (truck_id: number, filters: DocumentNameFilters, events: ServiceEvents = {}) => {
            const queryString = Object.entries(filters)
                .map(([key, value]) => `${key}=${value}`)
                .join('&');
            const url = EndPoints.TRUCK_DOCUMENT.GET_TRUCK_DOCUMENTS_BY_TRUCK.replace(
                ':id',
                truck_id.toString()
            );
            doGet({
                ...events,
                url: `${url}?${queryString}`,
                setFetching: setFetchingGetTruckDocuments
            });
        };

        const getTruckDocumentByTruckAndDocumentType = (
            truck_id: number,
            document_id: number,
            events: ServiceEvents = {}
        ) => {
            const url = `${EndPoints.TRUCK_DOCUMENT.HISTORY_BY_TRUCK_AND_TYPE}`;
            doGet({
                ...events,
                url: url
                    .replaceAll(':truck_id', truck_id.toString())
                    .replaceAll(':document_id', document_id.toString()),
                setFetching: setFetchingGetTruckDocumentsByTruckAndDocumentType
            });
        };


        const downloadDocument = (url: string, fileName: string): void => {
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        
        const getTruckDocumentBySet = (truckId: number, documentSetId: number) => {
            if (showLoading) showLoading('download', 'Preparando archivos...');
            
            const url = EndPoints.TRUCK_DOCUMENT.GET_BY_SET.replace(
                ':truck_id',
                truckId.toString()
            ).concat(`?document_set=${documentSetId}`);
            
            fetch(url, { headers: { ...fetchDefaultHeaders } })
                .then(async (response) => {
                    const message = response.headers.get('Message'); // Texto plano
                    const messageJson = response.headers.get('Message-Json'); // JSON estructurado
                    
                    if (!response.ok) {
                        const errorResponse = await response.json();
                        throw new Error(
                            errorResponse.message ||
                            message ||
                            'Error al intentar descargar el archivo ZIP.'
                        );
                    }
                    
                    // Leer el archivo blob (ZIP)
                    const blob = await response.blob();
                    return { blob, message, messageJson };
                })
                .then(({ blob, message, messageJson }) => {
                    // Descargar el archivo ZIP
                    const a = document.createElement('a');
                    const fileUrl = window.URL.createObjectURL(blob);
                    a.href = fileUrl;
                    a.download = 'documentos.zip';
                    a.click();
                    window.URL.revokeObjectURL(fileUrl);
                    
                    if (changeAnimation) changeAnimation('downloadSuccess', 'Archivos descargados con éxito', true);
                    if (hideLoading) hideLoading();
                    
                    // Procesar `Message-Json`
                    const parsedMessageJson = messageJson ? JSON.parse(messageJson) : null;
                    const messageList = parsedMessageJson?.downloaded_files || [];
                    const failedList = parsedMessageJson?.failed_files || [];
        
                    // Mostrar alertas detalladas
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: message || 'Archivos descargados exitosamente.',
                        message_list: [
                            ...messageList.map((file: string) => `Archivo descargado: ${file}`),
                            ...failedList.map((file: string) => `${file}`),
                        ],
                    });
                })
                .catch((error) => {
                    console.error('Error al intentar descargar el archivo ZIP:', error.message);
                    
                    if (changeAnimation) changeAnimation('error', error.message, true);
                    if (hideLoading) hideLoading();
                    
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: error.message || 'Error al intentar descargar el archivo ZIP.',
                    });
                });
        };
        const storeTruckDocument = (truckDocument: TruckDocument, events: ServiceEvents = {}) => {
            doPost({
                ...events,
                url: EndPoints.TRUCK_DOCUMENT.STORE_TRUCK_DOCUMENT,
                requestType: 'multipart',
                body: truckDocument,
                setFetching: setFetchingStoreTruckDocument
            });
        };

        return {
            fetchingGetTruckDocumentsByTruckAndDocumentType,
            fetchingGetTruckDocuments,
            storeTruckDocument,
            getTruckDocumentsByTruck,
            getTruckDocumentBySet,
            getTruckDocumentByTruckAndDocumentType,
            downloadDocument,
            fetchingStoreTruckDocument,
            fetchingGetTruckDocumentsByset
        };
    };

    export default useTruckDocumentService;
