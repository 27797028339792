import { Link } from 'react-router-dom';
import {
    InternalSettlement,
    InternalSettlementFilters,
    InternalSettlementTable
} from '../../../../app/models/InternalSettlement';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';

import { momentParseDate } from '../../../../helpers';
import { formatAmount, formatMoney } from '../../../../utils/utils';
import InternalSettlementFilter from './InternalSettlementFilter';
import LazyLoading from '../../../../components/LazyLoading';
import GetAllSummary from '../GetAllSummary';

import { AuthContext } from '../../../../contexts/AuthContext';

import circleGrey from '../../../../assets/images/icons/circle-color-grey.svg';
import circleRed from '../../../../assets/images/icons/circle-color-red.svg';
import circleGreen from '../../../../assets/images/icons/circle-color-green.svg';
import { useContext } from 'react';

interface Props {
    internalSettlements: InternalSettlement[];
    totalRows: number;
    allCredit: number;
    allDebit: number;
    pendingApproval: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    approved?: (id: number, value: boolean, message: string) => void;
    rejected?: (id: number, value: boolean, message: string) => void;
    showCheckingAccountHistory?: (id: number) => void;
    filter: InternalSettlementFilters;
    setFilter: (filter: InternalSettlementFilters) => void;
    paginationRowsPerPageOptions?: any[];
    downloadExcel?: () => void;
    onSearch: () => void;
}

const InternalSettlementDataTable = ({
    internalSettlements,
    totalRows,
    allCredit,
    allDebit,
    pendingApproval,
    loading = false,
    filter,
    setFilter,
    downloadExcel,
    show,
    approved,
    rejected,
    showCheckingAccountHistory,
    onSearch
}: Props) => {

    const { auth } = useContext(AuthContext);

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">
                    <img
                        src={
                            row.is_approved === true 
                                ? circleGreen 
                                : row.is_approved === false 
                                ? circleRed 
                                : circleGrey
                        }
                        alt="circle"
                        style={{
                            width: '25px',
                            height: '25px',
                            marginRight: '5px',
                            marginBottom: '3px'
                        }}
                    />
                    {row.id}
                </span>
            )
        },
        {
            name: 'Fecha de emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Tipo de movimiento',
            selector: (row: any) => row.movement_type,
            sortable: true,
            sortField: 'movement_type',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">{row.movement_type}</span>
            )
        },
                {
            name: 'Motivo',
            selector: (row: any) => row.description,
            sortable: true,
            sortField: 'motive',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">{row?.motive}</span>
            )
        },
                {
            name: 'Descripción',
            selector: (row: any) => row.description,
            sortable: true,
            sortField: 'description',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">{row.description}</span>
            )
        },

                {
            name: 'Conductor',
            selector: (row: any) => row?.carrier,
            sortable: true,
            sortField: 'carrier',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                    <span dangerouslySetInnerHTML={{ __html: row.carrier ? row.carrier : '' }} />
            )
        },
        {
            name: 'Id gastos',
            selector: (row: any) => row.expenses,
            sortable: false,
            sortField: 'expenses',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">{row.expenses}</span>
            )
        },


        {
            name: 'Monto',
            selector: (row: any) => row?.amount,
            sortable: true,
            sortField: 'amount',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <>
                    <span className="">
                        {formatAmount(row.amount ? row.amount : 0)} {row.currency}
                    </span>
                </>
            )
        },
        {
            name: 'Aprobado',
            selector: (row: any) => row?.is_approved,
            sortable: true,
            sortField: 'is_approved',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <>
                    <span className="">
                        {row.is_approved ? 'Si' : 'No'}
                    </span>
                </>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <div className="">
                {show && (
                    <ButtonTableAction
                        callbackFunction={() => show(row?.id ?? -1)}
                        classIcon={'mdi mdi-eye'}
                        colorIcon={'text-info'}
                        errorMessage={'No se puede mostrar este registro.'}
                        title={'Ver gastos'}
                    />
                )}

                {approved && auth?.roles?.includes('Gestor') && (
                    <ButtonTableAction
                        callbackFunction={() => approved(row?.id ?? -1, true, 'aprobado')}
                        classIcon={'mdi mdi-checkbox-marked-circle'}
                        colorIcon={'text-info'}
                        errorMessage={'No se puede aprobar este registro.'}
                        title={'Aprobar'}
                    />
                )}

                {rejected &&
                    (auth?.roles?.includes('Gestor') || (auth?.roles?.includes('Conductor') && !row.worker_attempt_used) ) && // Mostrar solo si no ha sido rechazado o no es Conductor
                    (
                        <ButtonTableAction
                            callbackFunction={() => rejected(row?.id ?? -1, false, 'rechazado')}
                            classIcon={'mdi mdi-close-circle'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede rechazar este registro.'}
                            title={'Rechazar'}
                        />
                    )
                }

                    {showCheckingAccountHistory && auth?.roles?.includes('Gestor') &&(
                            <ButtonTableAction
                                callbackFunction={() => showCheckingAccountHistory(row?.id ?? -1)}
                                classIcon={'mdi mdi-history'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede mostrar el historial de este registro.'}
                                title={'Mostrar historial'}
                            />
                    )}
                </div>
            )
        }
    ];
    const renderCustomMobileCards = (_data: any[], _columns: any[]) => {
        let previousDate = '';
        const desiredColumns = ['Motivo', 'Monto'];
    
        const toggleCollapse = (index: number) => {
            const content = document.getElementById(`collapse-${index}`);
            if (content) {
                content.style.display = content.style.display === 'none' ? 'block' : 'none';
            }
        };

        const getIconClass = (movementType: string) => {
            return movementType === 'Abono' ? 'mdi mdi-arrow-up' : 'mdi mdi-arrow-down';
        };
        return (
            <div>
                {_data.map((row: any, index: any) => {
                    const isNewDate = previousDate != momentParseDate(row.issue_date);
                    previousDate = momentParseDate(row.issue_date); // Actualiza la fecha anterior
                    return (
                        <>
                        {isNewDate && (
                            <div style={{ fontWeight: 'bold' , marginTop: 15}}>
                                {momentParseDate(row.issue_date)}
                            </div>
                        )}
                        <div
                            key={index}
                            style={{
                                border: '1px solid #ccc',
                                padding: '10px',
                                paddingLeft: 5,
                                paddingRight: 5,
                                marginLeft: -5,
                                marginRight: -5,
                                borderLeftColor: row.movement_type === 'Abono' ? '#28a745' : '#dc3545',
                                borderLeftWidth: 5,
                                cursor: 'pointer',
                                position: 'relative', // Necesario para posicionar la fecha
                            }}
                            onClick={() => toggleCollapse(index)}
                        >

                            <div className="row">
                                {_columns
                                    .filter((column: any) => desiredColumns.includes(column.name))
                                    .map((column: any, index: number) => (
                                        <div
                                            className={`col-${index % 2 === 0 ? '7' : '5 text-end'}`}
                                            key={column.selector}
                                        >
                                            {column.cell ? column.cell(row) : row[column.selector]}
                                        </div>
                                    ))}
                            </div>
                            <div
                                id={`collapse-${index}`}
                                style={{ display: 'none' }}
                                className="row mt-2"
                            >
                                {_columns
                                    .filter((column: any) => !desiredColumns.includes(column.name) && column.name !== 'Acciones' && column.name !== 'Id gastos')
                                    .map((column: any) => (
                                        <div
                                            className="col-12"
                                            key={column.selector}
                                        >
                                            <strong>{column.name}: </strong>
                                            {column.cell ? column.cell(row) : row[column.selector]}
                                        </div>
                                    ))}
                            </div>
                        </div>
                        </>);
                })}
            </div>
        );
    };
    
    return (
        <>
            <div className="row mt-3">
                <div className="col-lg-11 ms-auto">
                    <GetAllSummary loading={loading} allCredit={allCredit} allDebit={allDebit} pendingApproval={pendingApproval} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-auto">
                    <InternalSettlementFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
                <div className="col-auto ms-auto mb-2" style={{ paddingTop: 22 }}>
                    <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                        <i className="fa fa-file-excel" /> Descargar
                    </button>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={internalSettlements}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
                isMobileStacked={true}
                renderCustomMobileCards={renderCustomMobileCards}
            />
        </>
    );
};

export default InternalSettlementDataTable;
