import { EndPoints } from '../EndPoints';

import useFetch from '../../../hooks/useFetch';
import { useState, useContext } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { UserResponseDataForm, UserResponseFilters } from '../../models/UserResponse';
import { QuestionDataForm } from '../../models/Question';

const useUserResponseService = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetUserResponses, setFetchingGetUserResponses] =
        useState(false);
    const [fetchingGetUserResponsesBySurvey, setFetchingGetUserResponsesBySurvey] =
        useState(false);
    const [fetchingStoreUserResponse, setFetchingStoreUserResponse] =
        useState(false);
    const [fetchingStoreUserFiles, setFetchingStoreUserFiles] =
        useState(false);
    const [fetchingEditUserResponse, setFetchingEditUserResponse] =
        useState(false);
    const [fetchingUpdateUserResponse, setFetchingUpdateUserResponse] =
        useState(false);
    const [fetchingDeleteUserResponse, setFetchingDeleteUserResponse] =
        useState(false);
    const [fetchingCreateUserResponse, setFetchingCreateUserResponse] =
        useState(false);
    const [fetchingShowUserResponse, setFetchingShowUserResponse] =
        useState(false);
    const [fetchingActiveUserResponse, setFetchingActiveUserResponse] =
        useState(false);
 
    const getAllUserResponses = (
        filter: UserResponseFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.USER_RESPONSES.GET_USER_RESPONSES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetUserResponses
        });
    };

    const getAllUserResponsesBySurvey = (
        survey_id: number,
        filter: UserResponseFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.USER_RESPONSES.GET_USER_RESPONSES_BY_SURVEY}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':survey_id', survey_id.toString()),
            setFetching: setFetchingGetUserResponsesBySurvey
        });
    };

    const createUserResponse = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USER_RESPONSES.CREATE_USER_RESPONSE,
            setFetching: setFetchingCreateUserResponse
        });
    };

    const storeUserResponse = (
        userResponseDataForm: UserResponseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.USER_RESPONSES.STORE_USER_RESPONSE,
            body: userResponseDataForm,
            requestType: 'multipart',
            setFetching: setFetchingStoreUserResponse
        });
    };

    const storeUserFiles = (
        userResponseDataForm: UserResponseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.USER_RESPONSES.STORE_USER_FILES,
            body: userResponseDataForm,
            requestType: 'multipart',
            setFetching: setFetchingStoreUserFiles
        });
    };

    const editUserResponse = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USER_RESPONSES.EDIT_USER_RESPONSE.replace(':id', id.toString()),
            setFetching: setFetchingEditUserResponse
        });
    };

    const showUserResponse = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USER_RESPONSES.SHOW_USER_RESPONSE.replace(':id', id.toString()),
            setFetching: setFetchingShowUserResponse
        });
    };

    const updateUserResponse = (
        id: number,
        userResponseDataForm: UserResponseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.USER_RESPONSES.UPDATE_USER_RESPONSE.replace(':id', id.toString()),
            body: userResponseDataForm,
            setFetching: setFetchingUpdateUserResponse
        });
    };

    const deleteUserResponse = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.USER_RESPONSES.DELETE_USER_RESPONSE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteUserResponse
        });
    };

    const activeUserResponse = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.USER_RESPONSES.ACTIVE_USER_RESPONSE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveUserResponse
        });
    };

    return {
        getAllUserResponses,
        getAllUserResponsesBySurvey,
        createUserResponse,
        storeUserResponse,
        storeUserFiles,
        editUserResponse,
        updateUserResponse,
        deleteUserResponse,
        showUserResponse,
        activeUserResponse,
        fetchingGetUserResponsesBySurvey,
        fetchingShowUserResponse,
        fetchingGetUserResponses,
        fetchingStoreUserResponse,
        fetchingStoreUserFiles,
        fetchingEditUserResponse,
        fetchingUpdateUserResponse,
        fetchingDeleteUserResponse,
        fetchingCreateUserResponse,
        fetchingActiveUserResponse
    };
};

export default useUserResponseService;
