import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AppContext } from '../../../contexts/AppContext';
import useSurveyService from '../../../app/services/hooks/useSurveyService';
import { Survey } from '../../../app/models/Survey';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import { defaultQuestionDataForm, QuestionDataForm } from '../../../app/models/Question';
import { Section } from '../../../app/models/Section';
import QuestionCard from '../components/QuestionCard';
import NavQuestions from '../components/NavQuestions';
import UserResponseConfirm from '../components/UserResponseConfirm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { InputResponseType } from '../../../app/shared/enums';
import useUserResponseService from '../../../app/services/hooks/useUserResponseService';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { UserResponseDataForm } from '../../../app/models/UserResponse';
import { UserResponseFileDataForm } from '../../../app/models/UserResponseFile';


type RouterParams = {
    id: string;
};

const RespondSurvey = () => {
    const { id } = useParams<RouterParams>();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { getSurveyById, fetchingGetSurvey } = useSurveyService();
    const { storeUserResponse, fetchingStoreUserResponse } = useUserResponseService();
    const { navigationPage } = useNavigationPage();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Encuesta',
            url: '/surveys',
            isActive: false
        },
        {
            name: 'Respuestas #' + id,
            url: '/surveys/' + id + '/respond',
            isActive: true
        }
    ];

    const [ survey, setSurvey ] = useState<Survey>()
    const [ sections, setSections ] = useState<Section[]>()
    const [ allQuestions, setAllQuestions ] = useState<QuestionDataForm[]>([]);

    const [ checklistQuestions, setChecklistQuestions ] = useState<QuestionDataForm[]>([]);
    const [ answeredQuestions, setAnsweredQuestions ] = useState<QuestionDataForm[]>([]);
    const [ historyQuestions, setHistoryQuestions ] = useState<QuestionDataForm[]>([]);

    const [ userResponseFiles, setUserResponseFiles ] = useState<UserResponseFileDataForm[]>([]);

    const [ currentQuestion, setCurrentQuestion ] = useState<number>(1);
    const [ selectedQuestion, setSelectedQuestion ] = useState<QuestionDataForm>(defaultQuestionDataForm);
    const [ showResume, setShowResume ] = useState<boolean>(false);
    const [ showConfirm, setShowConfirm ] = useState<boolean>(false);
    

    useEffect(() => {
        getSurveyById(Number(id), {
            onSuccess: (response: ServiceResponse) => {
                setSurvey(response.data.survey);
                setSections(response.data.survey.sections);
                setAllQuestions(response.data.questions)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
            }
        })
    }, [id]);

    //useEffect para setear la lista de preguntas con la primera sección
    useEffect(() => {
        if (sections?.length && sections[0].questions?.length) {
            const firstQuestion = sections[0].questions[0];
            setChecklistQuestions(sections[0].questions);
            setSelectedQuestion(firstQuestion);
            setHistoryQuestions([firstQuestion]);
        }
    }, [sections]);

    // useEffect que escucha la respuesta y revisa si hay next_question para agregar la nueva seccion a la lista de preguntas, 
    // y guarda la respuesta en el histórico
    useEffect(() => {

        if(showConfirm) return;

        if(selectedQuestion.response_type === InputResponseType.RADIOBUTTON 
            || selectedQuestion.response_type === InputResponseType.SELECT){

            const selectedOption = selectedQuestion.options?.find(op => op.is_response === true);
            const thisChckLstSoFar = [...answeredQuestions];
            thisChckLstSoFar.push(selectedQuestion);

            if(selectedOption?.next_question !== null){
                const nxtQuestion = allQuestions.find(q => q.id === selectedOption?.next_question_id);
                const nxtQtnSection = sections?.find(scn => scn.id === nxtQuestion?.section_id);
                const nxtQuestionIndex = nxtQtnSection?.questions?.findIndex(q => q.id === nxtQuestion?.id);
                const nxtSctnQuestions = nxtQtnSection?.questions?.slice(nxtQuestionIndex);

                if(nxtSctnQuestions){
                    setChecklistQuestions([
                        ...thisChckLstSoFar,
                        ...nxtSctnQuestions
                    ])
                }

            }else {
                const thisSctn = sections?.find(scn => scn.id === selectedQuestion.section_id);
                const selectedQstnIndexInThisScn = thisSctn?.questions?.findIndex(q => q.id === selectedQuestion.id);
                const thisSctnNxtQstns = thisSctn?.questions?.slice(selectedQstnIndexInThisScn);

                if(thisSctnNxtQstns && thisSctnNxtQstns.length > 1){
                    thisSctnNxtQstns.shift();
                    setChecklistQuestions([
                        ...thisChckLstSoFar,
                        ...thisSctnNxtQstns
                    ]);
                }else {
                    setChecklistQuestions([
                        ...thisChckLstSoFar,
                    ]);
                }
            }
        }

        const modifiedQuestions = historyQuestions.map(q => {
            if (q.id === selectedQuestion.id) return selectedQuestion
            else return q
        })
        setHistoryQuestions(modifiedQuestions);

    }, [selectedQuestion]);


    // useEffect que escucha el número de pregunta y cambia la pregunta para mostrarlo
    // y guarda la respuesta de la pregunta anterior en la lista de respuesta
    useEffect(() => {

        const questionToShow = checklistQuestions[currentQuestion-1];

        if (questionToShow){
            const historyAnswerd = historyQuestions.find(q => q.id === questionToShow.id );

            if (historyAnswerd){
                setSelectedQuestion(historyAnswerd);
            } else{
                setSelectedQuestion(questionToShow);
                historyQuestions.push(questionToShow)
            }
        } 

        if(historyQuestions.length > 1){
            const lastQuestion = checklistQuestions[currentQuestion-2]

            if(lastQuestion){
                const lastAnswrd = historyQuestions.find(q => q.id === lastQuestion.id );

                if(lastAnswrd){
                    const answerds = [
                        ...answeredQuestions,
                        lastAnswrd
                    ]
                    setAnsweredQuestions(answerds)
                }
            }

            if(answeredQuestions.length >= currentQuestion){
                const slicedAnswerds = answeredQuestions.slice(0, currentQuestion-1);
                setAnsweredQuestions(slicedAnswerds);
            }
        }
        
    }, [currentQuestion]);

    useEffect(() => {
        if(showConfirm && checklistQuestions.length === 1){
            setAnsweredQuestions(historyQuestions)
        }
    }, [showConfirm]);


    const confirmForm = () => {
        setShowResume(true);
    };


    const store = () => {
        const _text = 'Está a punto de enviar la encuesta #' + survey?.title;
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Enviando encuesta...');

                        const questionsWithImages = answeredQuestions.filter(
                            question => question.selectedImages && question.selectedImages.length > 0
                        );
                        
                        const questionsWithoutImages = answeredQuestions.filter(
                            question => !question.selectedImages || question.selectedImages.length === 0
                        );

                        const files: UserResponseFileDataForm[] = []
                        questionsWithImages.forEach((q, i) => {
                            q.selectedImages?.forEach((file, index)=> {
                                const newFile: UserResponseFileDataForm = {
                                    question_id: q.id,
                                    question_text: q.question_text,
                                    file: file
                                }
                                files.push(newFile)
                            })
                        });
                        
                        const userResponseDataForm = {
                            questions: JSON.stringify(questionsWithoutImages),
                            survey_id: survey?.id,
                            files: files
                        }

                        storeUserResponse(userResponseDataForm, {
                            onSuccess: (response: ServiceResponse) => {
                                
                                const user_response_id = response.data.user_response_id;
                                toast.success(response.message, {
                                    autoClose: 2500
                                });

                                if (hideLoading) hideLoading();
                                    useReactConfirmAlert().successAlert({
                                        title: 'Éxito',
                                        message: response.message
                                    });
                                    navigationPage('/');
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            },
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La encuesta no se ha enviado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };
    

    if (fetchingGetSurvey) return <LazyLoading />

    return (
        <>
            {/* <Breadcrumbs pageSection={`Responder Encuesta #${id}`} breadcrumbs={breadcrumbs} /> */}
            
            <div className="">
                { selectedQuestion && !showResume ? (
                    <QuestionCard 
                        currentQuestion={selectedQuestion} 
                        setCurrentQuestion={setSelectedQuestion}
                        showConfirm={showConfirm}
                        isSurveyResponse={true}
                    />
                ) : null }
                { showResume ? (
                    <UserResponseConfirm 
                        responses={answeredQuestions} 
                        isConfimation={true}                       
                    />
                ) : null }
            </div>
            <div className="">
                <NavQuestions 
                    totalQuestions={checklistQuestions.length} 
                    currentQuestion={currentQuestion} 
                    setCurrentQuestion={setCurrentQuestion}
                    showResume={showResume}
                    setShowResume={setShowResume}
                    confirmForm={confirmForm}
                    setShowConfirm={setShowConfirm}
                    storeForm={store}
                    showConfirm={showConfirm}
                    questionSelected={selectedQuestion}
                />
            </div>
        </>
    )
}

export default RespondSurvey


