export interface Document {
    id?: number;
    document_name: string;
    is_active: boolean;
}

export interface DocumentDataForm {
    id?: number;
    document_name: string;
    description?: string;
    is_active: boolean;
}

export interface DocumentFilters {
    id?: number | null;
    document_name: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface DocumentNameFilters {
    id?: number | null;
    document_name: string | null;
    document_set?: string | null;
}

export const defaultDocument: Document = {
    document_name: '',
    is_active: true
};
