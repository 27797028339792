import React from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { AIToll, AITollFilters } from '../../../../app/models/AITolls';

interface Props {
    pendingTolls: AIToll[];
    totalRows: number;
    loading?: boolean;
    filter: AITollFilters;
    setFilter: (filter: AITollFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const AIDataTable: React.FC<Props> = ({
    pendingTolls,
    totalRows,
    loading = false,
    filter,
    setFilter,
    callbackSuccess,
    onSearch,
}) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: AIToll) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: AIToll) => <span>{row.id}</span>,
        },
        {
            name: 'Orden de Transporte',
            selector: (row: AIToll) => row.transport_order_id,
            sortable: true,
            sortField: 'transport_order_id',
            cell: (row: AIToll) => <span>{row.transport_order_id}</span>,
        },
        {
            name: 'Tipo de Documento',
            selector: (row: AIToll) => row.expense_document_type,
            sortable: true,
            sortField: 'expense_document_type',
            cell: (row: AIToll) => <span>{row.expense_document_type}</span>,
        },
        {
            name: 'Monto',
            selector: (row: AIToll) => row.amount ?? 'N/A',
            sortable: true,
            sortField: 'amount',
            cell: (row: AIToll) => <span>{row.amount ?? 'N/A'}</span>,
        },
        {
            name: 'Fecha',
            selector: (row: AIToll) => row.issue_date ?? 'N/A',
            sortable: true,
            sortField: 'issue_date',
            cell: (row: AIToll) => <span>{row.issue_date ?? 'N/A'}</span>,
        },
        {
            name: 'Foto',
            selector: (row: AIToll) => row.photo,
            sortable: false,
            cell: (row: AIToll) =>
                row.photo ? (
                    <a href={row.photo} target="_blank" rel="noreferrer">
                        Ver Foto
                    </a>
                ) : (
                    'N/A'
                ),
        },
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    {/* Aquí se podría incluir un componente de filtro adicional si es necesario */}
                </div>
            </div>
            <DefaultDataTable
                columns={columns}
                data={pendingTolls}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default AIDataTable;
