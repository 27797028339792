import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { CalendarEventDataForm } from '../../../../app/models/Event';
import { ShiftWorkDayTravelExpense } from '../../../../app/models/Shift';

type Props = {
    calendarEventDataForm: CalendarEventDataForm;
    setCalendarEventDataForm: (calendarEventDataForm: CalendarEventDataForm) => void;
};

const ShiftWorkDaysTable = ({
    calendarEventDataForm,
    setCalendarEventDataForm,
}: Props) => {

    useEffect(() => {
        const updatedTravelExpenses: ShiftWorkDayTravelExpense[] = []

        calendarEventDataForm.shift_days?.map((row, i) => {
            const workDayExpense = {
                position: i,
                shift_work_id: calendarEventDataForm.shift_work_id,
                travel_expense: row.travel_expense,
                travel_expense_id: row.travel_expense?.id,
            }

            updatedTravelExpenses.push(workDayExpense)
        })

        setCalendarEventDataForm({
            ...calendarEventDataForm,
            shift_Work_day_travel_expense: updatedTravelExpenses
        });

    }, [calendarEventDataForm.shift_days]);


    return (
        <>
            <div className="col-md-12 my-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Días</th>
                            <th>Tipo de turno</th>
                            <th>Viáticos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calendarEventDataForm?.shift_days?.map((item, index) => (
                            <tr key={item.id}>
                                <td>{item.number_of_days}</td>
                                <td>{item.event_type?.name}</td>
                                <td>{item.travel_expense?.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ShiftWorkDaysTable
