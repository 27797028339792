import useFetch from '../../../hooks/useFetch';
import { useContext, useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { HopperDocument } from '../../models/Hopper';
import { DocumentNameFilters } from '../../models/Document';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { fetchDefaultHeaders } from '../shared/vars';


const useHopperDocumentService = () => {
    const { doGet, doPost } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);


    const [
        fetchingGetHopperDocumentsByHopperAndDocumentType,
        setFetchingGetHopperDocumentsByHopperAndDocumentType
    ] = useState(false);
    const [fetchingGetHopperDocuments, setFetchingGetHopperDocuments] = useState(false);
    const [fetchingStoreHopperDocument, setFetchingStoreHopperDocument] = useState(false);
    const [fetchingGetHopperDocumentsByset, setFetchingGetHopperDocumentBySet] = useState(false);

    const getHopperDocumentsByHopper = (hopper_id: number, filters: DocumentNameFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = EndPoints.HOPPER_DOCUMENT.GET_HOPPER_DOCUMENTS_BY_HOPPER.replace(
            ':id',
            hopper_id.toString()
        );
        doGet({
            ...events,
            url: `${url}?${queryString}`,
            setFetching: setFetchingGetHopperDocuments
        });
    };

    const getHopperDocumentBySet = (hopperId: number, documentSetId:number) => {
        if (showLoading) showLoading('download', 'Preparando archivos...');
    
        const url = EndPoints.HOPPER_DOCUMENT.GET_BY_SET.replace(
            ':hopper_id',
            hopperId.toString()
        ).concat(`?document_set=${documentSetId}`);
    
        fetch(url, { headers: { ...fetchDefaultHeaders } })
            .then(async (response) => {
                const message = response.headers.get('Message'); // Texto plano
                const messageJson = response.headers.get('Message-Json'); // JSON estructurado
    
                if (!response.ok) {
                    const errorResponse = await response.json();
                    throw new Error(
                        errorResponse.message ||
                        message ||
                        'Error al intentar descargar el archivo ZIP.'
                    );
                }
    
                // Leer el archivo blob (ZIP)
                const blob = await response.blob();
                return { blob, message, messageJson };
            })
            .then(({ blob, message, messageJson }) => {
                // Descargar el archivo ZIP
                const a = document.createElement('a');
                const fileUrl = window.URL.createObjectURL(blob);
                a.href = fileUrl;
                a.download = 'documentos.zip';
                a.click();
                window.URL.revokeObjectURL(fileUrl);
    
                if (changeAnimation) changeAnimation('downloadSuccess', 'Archivos descargados con éxito', true);
                if (hideLoading) hideLoading();
                    // Procesar `Message-Json`
                const parsedMessageJson = messageJson ? JSON.parse(messageJson) : null;
                const messageList = parsedMessageJson?.downloaded_files || [];
                const failedList = parsedMessageJson?.failed_files || [];
    
                // Mostrar alertas detalladas
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: message || 'Archivos descargados exitosamente.',
                    message_list: [
                        ...messageList.map((file: string) => `Archivo descargado: ${file}`),
                        ...failedList.map((file: string) => `${file}`),
                    ],
                });
            })
            .catch((error) => {
                console.error('Error al intentar descargar el archivo ZIP:', error.message);
    
                if (changeAnimation) changeAnimation('error', error.message, true);
                if (hideLoading) hideLoading();
    
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: error.message || 'Error al intentar descargar el archivo ZIP.',
                });
            });
    };

    const storeHopperDocument = (hopperDocument: HopperDocument, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.HOPPER_DOCUMENT.STORE_HOPPER_DOCUMENT,
            requestType: 'multipart',
            body: hopperDocument,
            setFetching: setFetchingStoreHopperDocument
        });
    };

    const getHopperDocumentByHopperAndDocumentType = (
        hopper_id: number,
        document_id: number,
        events: ServiceEvents = {}
    ) => {
        const url = `${EndPoints.HOPPER_DOCUMENT.HISTORY_BY_HOPPER_AND_TYPE}`;
        doGet({
            ...events,
            url: url
                .replaceAll(':hopper_id', hopper_id.toString())
                .replaceAll(':document_id', document_id.toString()),
            setFetching: setFetchingGetHopperDocumentsByHopperAndDocumentType
        });
    };

    return {
        fetchingGetHopperDocumentsByHopperAndDocumentType,
        fetchingGetHopperDocuments,
        storeHopperDocument,
        getHopperDocumentsByHopper,
        getHopperDocumentByHopperAndDocumentType,
        getHopperDocumentBySet,
        fetchingStoreHopperDocument,
        fetchingGetHopperDocumentsByset
    };
};

export default useHopperDocumentService;
