import React, {useEffect, useState} from 'react';

type Props = {
    imageURLs: string[]; 
    eraseImage?: (index: number)=>void;
};

const FileCarrousel = ({imageURLs, eraseImage}: Props) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(1);
    const [thumbnails, setThumbnails] = useState<string[]>([]);

    useEffect(()=>{
        if (currentImageIndex > imageURLs.length) {
            setCurrentImageIndex(Math.max(1, imageURLs.length));
        }
    },[imageURLs])

    useEffect(()=>{
        let first;
        let end;

        if(imageURLs.length <= 3 || currentImageIndex <= 2){
            first = 0;
            end = 3;
        }else if(currentImageIndex > 2 && imageURLs.length >= currentImageIndex+1){
            first = currentImageIndex-2
            end = currentImageIndex+1;
        }else if(currentImageIndex == imageURLs.length){
            first = currentImageIndex-3
            end = currentImageIndex;
        }

        const imagesToShow = imageURLs.slice(first, end);
        setThumbnails(imagesToShow);

    },[currentImageIndex, imageURLs])

    const handleNextImage = () => {
        if(currentImageIndex + 1 <= imageURLs.length){
            setCurrentImageIndex((prevIndex) => (prevIndex + 1));
        }
    };

    const handlePrevImage = () => {
        if(currentImageIndex - 1 > 0){
            setCurrentImageIndex((prevIndex) => (prevIndex - 1));
        }
    };

    const deleteFile = () => {
        if (eraseImage) eraseImage(currentImageIndex);
    }

    return (
        <div>
            {imageURLs.length > 0 && (
                <div className="d-flex justify-content-center position-relative p-1">
                    <img
                        src={imageURLs[currentImageIndex - 1]}
                        alt="Preview"
                        style={{ width: '250px', height: '250px' }}
                        className="img-thumbnail"
                    />
                    {eraseImage && (<button 
                        type="button" 
                        className="btn-close" 
                        aria-label="Close"
                        onClick={deleteFile}
                        style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            backgroundColor: 'white',
                            padding: '5px',
                            borderRadius: '50%'
                        }}
                    />)}
                </div>
            )}

            <div className="d-flex flex-wrap mt-3">
                {imageURLs.length > 0 && <>
                    <div className="col">
                        <button
                            className="btn btn-rounded btn-outline-primary"
                            onClick={handlePrevImage}
                        >
                            <i className="fa fa-chevron-left" />
                        </button>
                    </div>
                        
                    {thumbnails.map((image, index) => {
                        return (
                            <>
                                <div key={index} className="col-auto p-1">
                                    <img
                                        src={image}
                                        alt="Preview"
                                        style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                                        className="img-thumbnail"
                                    />
                                </div>
                            </>
                        )}
                    )}
                        
                    <div className="col text-end">
                        <button
                            className="btn btn-rounded btn-outline-primary"
                            onClick={handleNextImage}
                        >
                            <i className="fa fa-chevron-right" />
                        </button>
                    </div>
                </>}
            </div>

            {imageURLs.length > 0 && (
                <div className="mt-2 text-center">
                    {currentImageIndex} de {imageURLs.length}
                </div>
            )}
        </div>
    )
}

export default FileCarrousel
