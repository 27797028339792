import React, { useContext, useEffect, useState } from 'react';
import { Place } from '../../app/models/Place';
import { ServiceEventsDocuments, ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { InternalSettlement, InternalSettlementFilters } from '../../app/models/InternalSettlement';
import useInternalSettlementService from '../../app/services/hooks/useInternalSettlementService';
import InternalSettlementDataTable from './components/tables/InternalSettlementDataTable';
import InternalSettlementShow from './components/InternalSettlementShow';
import useNavigationPage from '../../hooks/useNavigationPage';
import { AuthContext } from '../../contexts/AuthContext';
import useCheckingAccountService from '../../app/services/hooks/useCheckingAccount';
import { CheckingAccountHistoryDataForm, CheckingAccountHistoryFilter } from '../../app/models/CheckingAccountHistory';
import CheckingAccountHistoryContainer from '../CheckingAccount/components/CheckingAccountHistoryContainer';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Liquidación interna',
        url: '/internal-settlements',
        isActive: true
    }
];

const InternalSettlements = () => {
    const history = useHistory();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        fetchingGetInternalSettlements,
        fetchingGetExcelInternalSettlements,
        getExcelAllInternalSettlements,
        getInternalSettlements, 
    } = useInternalSettlementService();


    const {rejectCheckingAccount, approvedCheckingAccount, getCheckingAccountHistory} = useCheckingAccountService();

    const [internalSettlements, setInternalSettlements] = useState<InternalSettlement[]>([]);
    const [showingShow, setShowingShow] = useState(false);
    const [internalSettlementIdShow, setInternalSettlementIdShow] = useState<number>(-1);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [totalRowsHistory, setTotalRowsHistory] = useState<number>(0);

    const [allCredit, setAllCredit] = useState<number>(0);
    const [allDebit, setAllDebit] = useState<number>(0);
    const [pendingApproval, setPendingApproval] = useState<number>(0);
    const [checkingAccountHistoryIdGet, setCheckingAccountHistoryIdGet] = useState<number>(-1);
    const [checkingAccountHistories, setCheckingAccountHistories] = useState<CheckingAccountHistoryDataForm[]>([]);
    const [filterHistory, setFilterHistory]= useState<CheckingAccountHistoryFilter>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const { navigationPage } = useNavigationPage();
    const { auth } = useContext(AuthContext);

    const [InternalSettlementFilter, setInternalSettlementFilter] =
        useState<InternalSettlementFilters>({
            page: 1,
            per_page: 10,
            sort: 'issue_date',
            order: 'desc',
            issue_date_from: moment().date(1).format('YYYY-MM-DD'),
            issue_date_until: moment().format('YYYY-MM-DD'),
            without_viatic_transport: auth?.roles?.includes('Conductor'),
        });

    const [showingGetCheckingAccountHistories, setShowingGetCheckingAccountHistories] = useState(false);
    const [hasRejected, setHasRejected] = useState<boolean>(false)

    useEffect(() => {
        reloadTable();
    }, [InternalSettlementFilter.page, InternalSettlementFilter.per_page, InternalSettlementFilter.sort, InternalSettlementFilter.order]);

    useEffect(() => {
        reloadTableHistory();
    }, [filterHistory]);

    useEffect(() => {
        if (checkingAccountHistoryIdGet !== -1 && showingGetCheckingAccountHistories) {
            getHistoryCheckingAccount(checkingAccountHistoryIdGet);
        }
    }, [checkingAccountHistoryIdGet, showingGetCheckingAccountHistories]);

    const getAllInternalSettlement = () => {
        getInternalSettlements(InternalSettlementFilter, {
            onSuccess: (response: ServiceResponse) => {
                setInternalSettlements(response.data.internal_settlements);
                setTotalRows(response.data.total_rows);
                setAllCredit(response.data.all_credit);
                setAllDebit(response.data.all_debit);
                setPendingApproval(response.data.pending_approvals)

            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const getHistoryCheckingAccount = (checkingAccount: number) => {
        getCheckingAccountHistory(checkingAccount, filterHistory, {
            onSuccess: (response: ServiceResponse) => {
                setCheckingAccountHistories(response.data.checking_account_histories);
                setTotalRowsHistory(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000,
                })
                hideGetCheckingAccountHistories();
            }
        });
    };

    const reloadTableHistory = () => {
        getHistoryCheckingAccount(checkingAccountHistoryIdGet);
    };

    const reloadTable = () => {
        getAllInternalSettlement();
    };

    const downloadExcel = () => {
        if (showLoading) showLoading('download', 'Recuperando documento...');
        getExcelAllInternalSettlements(InternalSettlementFilter, {
            onSuccess: (message: string) => {
                if (changeAnimation) changeAnimation('downloadSuccess', message, true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const aprroved = (id: number) => {
        const confirmationText = `La transferencia #${id} está a punto de ser aprobada.`;
    
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: confirmationText,
            buttons: {
                confirmButton: {
                    label: 'Sí, confirmar',
                    onClick: () => {
                        approvedCheckingAccount(id, {
                            onSuccess: () => {
                                useReactConfirmAlert().successAlert({
                                    title: 'Aprobada',
                                    message: 'La transferencia ha sido aprobada con éxito.',
                                });
                                reloadTable();

                            },
                            onError: (error) => {
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: `No se pudo aprobar la transferencia: ${error.message}`,
                                });
                            },
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La transferencia no ha sido aprobada.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };


    const rejected = (id: number) => {
        const confirmationText = `La transferencia #${id} está a punto de ser rechazada.`;
    
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: confirmationText,
            buttons: {
                confirmButton: {
                    label: 'Sí, confirmar',
                    onClick: () => {
                        rejectCheckingAccount(id, {
                            onSuccess: () => {
                                useReactConfirmAlert().successAlert({
                                    title: 'Rechazada',
                                    message: 'La transferencia ha sido rechazada con éxito.',
                                });
                                reloadTable();

                            },
                            onError: (error) => {
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: `No se pudo rechazar la transferencia: ${error.message}`,
                                });
                            },
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La transferencia no ha sido rechazada.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const show = (internalSettlementId: number) => {
        setShowingShow(true);
        setInternalSettlementIdShow(internalSettlementId);
    };

    const hideShow = () => {
        setShowingShow(false);
        setInternalSettlementIdShow(-1);
    };
    
    const showGetCheckingAccountHistories = (checkingAccount: number) => {
        setShowingGetCheckingAccountHistories(true);
        setCheckingAccountHistoryIdGet(checkingAccount);
    };

    const hideGetCheckingAccountHistories = () => {
        setShowingGetCheckingAccountHistories(false);
        setCheckingAccountHistoryIdGet(-1);
    };

    

    return (
        <>
            <Breadcrumbs pageSection="Liquidación interna" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <InternalSettlementDataTable
                    internalSettlements={internalSettlements}
                    loading={fetchingGetInternalSettlements}
                    filter={InternalSettlementFilter}
                    setFilter={setInternalSettlementFilter}
                    totalRows={totalRows}
                    allCredit={allCredit}
                    allDebit={allDebit}
                    pendingApproval={pendingApproval}
                    downloadExcel={downloadExcel}
                    show={show}
                    approved={aprroved}
                    rejected={rejected}
                    showCheckingAccountHistory={showGetCheckingAccountHistories}
                    onSearch={reloadTable}
                />
            </DefaultCard>
            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title="Ver liquidación interna"
                    backdrop={true}
                    showFooter={false}
                >
                    <InternalSettlementShow
                        internalSettlementId={internalSettlementIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}

            { showingGetCheckingAccountHistories? (
                <DefaultModal
                    show={showingGetCheckingAccountHistories}
                    handleClose={hideGetCheckingAccountHistories}
                    title="Historial de transferencia"
                    backdrop={true}
                    showFooter={false}
                    size="lg"
                >
                    <CheckingAccountHistoryContainer
                        checkingAccountId={checkingAccountHistoryIdGet}
                        checkingAccountHistories={checkingAccountHistories}
                        totalRowsHistory={totalRowsHistory}
                        onCancel={hideGetCheckingAccountHistories}
                        onError={hideGetCheckingAccountHistories}
                        filterHistory={filterHistory}
                        setFilterHistory={setFilterHistory}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default InternalSettlements;
