import { Currency } from './Currency';
import { EventType } from './EventType';

export interface TravelExpense {
    id?: number;
    travel_expense_id?: number;
    budget?: string;
    name?: string;
    start_date?: Date;
    end_date?: Date;
    eventTypes?: EventType[];
    currency?: Currency;
    currency_id?: number;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface TravelExpenseFilters {
    id?: number | null;
    budget?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    is_active?: boolean;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface TravelExpenseDataForm {
    id?: number;
    budget: string;
    start_date?: Date;
    name?: string;
    end_date?: Date;
    event_types?: EventType[];
    event_type_ids?: number[];
    currency_id?: number;
    currency?: Currency | undefined;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export const defaultTravelExpenseDataForm: TravelExpenseDataForm = {
    budget: '',
    currency_id: 0,
    start_date: undefined,
    end_date: undefined,
    event_types: undefined
};
