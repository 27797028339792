import React, { useContext, useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import DocumentsDataTable from '../../../components/DocumentsDataTable';
import { WorkerDocument } from '../../../app/models/Workers';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useWorkerDocumentService from '../../../app/services/hooks/useWorkerDocumentService';
import { toast } from 'react-toastify';
import { DocumentNameFilters } from '../../../app/models/Document';
import { useHistory } from 'react-router-dom';
import DefaultModal from '../../../components/default/DefaultModal';
import WorkerDocumentHistory from './WorkerDocumentHistory';
import { Worker } from '../../../app/models/Workers';
import WorkerDocumentCreate from './WorkerDocumentCreate';
import BankAccountsDataTable from './tables/BankAccountsDataTable';
import { BankAccount } from '../../../app/models/BankAccountType';
import useBankAccountService from '../../../app/services/hooks/useBankAccount';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import WorkerBankAccountCreate from './WorkerBankAccountCreate';
import WorkerBankAccountEdit from './WorkerBankAccountEdit';
import { DocumentSet } from '../../../app/models/DocumentSet';

interface Props {
    worker: Worker;
    documentSets: DocumentSet[]
}

const DocumentAndBankAccounts = ({ worker,documentSets }: Props) => {

    const history = useHistory();
    const { getWorkerDocumentsByWorker, getWorkerDocumentBySet, fetchingGetWorkerDocuments } = useWorkerDocumentService();
    const { getBankAccountByWorker, deleteBankAccount, fetchingGetBankAccountByWorker } = useBankAccountService();
    const { showLoading, hideLoading } = useContext(AppContext);

    const [workerDocuments, setWorkerDocuments] = useState<WorkerDocument[]>([]);
    const [documentTypes, setDocumentTypes] = useState<any[]>([]);
    const [filter, setFilter] = useState<DocumentNameFilters>({document_name: ''});
    const [showingHistoryDocument, setShowingHistoryDocument] = useState(false);
    const [showingCreateDocument, setShowingCreateDocument] = useState(false);
    const [showingCreateBankAccount, setShowingCreateBankAccount] = useState(false);
    const [showingEditBankAccount, setShowingEditBankAccount] = useState(false);
    const [typeDocumentSelected, setTypeDocumentSelected] = useState<any>();
    const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([])
    const [bankAccountId, setBankAccountId] = useState<number>(-1);

    useEffect(() => {
        getDocuments(Number(worker?.id));
        getBankAccounts(Number(worker?.id));
    }, []);

    const getDocuments = (id: number) => {
        getWorkerDocumentsByWorker(id, filter, {
            onSuccess: (response: ServiceResponse) => {
                setWorkerDocuments(response.data.worker_documents);
                setDocumentTypes(response.data.worker_documents);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const getBankAccounts = (id: number) => {
        getBankAccountByWorker(id, {
            onSuccess: (response: ServiceResponse) => {
                setBankAccounts(response.data.bank_accounts);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    
    const destroy = (id: number) => {
        const _text = 'Está a punto de eliminar la Cuenta Bancaria #' + id;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando Cuenta Bancaria...');
                        deleteBankAccount(id, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadBankAccountsTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La Cuenta Bancaria no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const handleGetDocumentSet = (documentSetId: number): void => {
        if (!documentSetId) {
            toast.error('Seleccione un set de documentos');
            return;
        }
        getWorkerDocumentBySet(Number(worker?.id), documentSetId);
    };
    


    const showCreateDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingCreateDocument(true);
    };

    const hideCreateDocument = () => {
        setShowingCreateDocument(false);
    };

    const showHistoryDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingHistoryDocument(true);
    };

    const hideHistoryDocument = () => {
        setShowingHistoryDocument(false);
    };

    const showCreateBankAccount = () => {
        setShowingCreateBankAccount(true);
    };

    const hideCreateBankAccount = () => {
        setShowingCreateBankAccount(false);
    }

    const showEditBankAccount = (id: number) => {
        setShowingEditBankAccount(true);
        setBankAccountId(id)
    };

    const hideEditBankAccount = () => {
        setShowingEditBankAccount(false);
    }

    const reloadDocumentsTable = () => {
        getDocuments(Number(worker?.id));
    }

    const reloadBankAccountsTable = () => {
        getBankAccounts(Number(worker?.id));
    }

    return (
        <div>
            <Tabs  id="document-bank-accounts-tabs" className="mb-3">
                <Tab eventKey="documents-data" title="Documentos">
                    <div className="h3 mt-0 mb-2 card-title">
                        Documentos
                    </div>
                    <DocumentsDataTable
                        data={workerDocuments}
                        create={showCreateDocument}
                        showHistory={showHistoryDocument}
                        progressPending={fetchingGetWorkerDocuments}
                        filter={filter}
                        setFilter={setFilter}
                        onSearch={reloadDocumentsTable}
                        documentSets={documentSets}
                        getDocumentSet={handleGetDocumentSet}
                    />

                    {showingCreateDocument ? (
                        <DefaultModal
                            show={showingCreateDocument}
                            handleClose={hideCreateDocument}
                            title="Agregar Documento"
                            backdrop={true}
                            showFooter={false}
                        >
                            <WorkerDocumentCreate
                                onSaved={() => {
                                    hideCreateDocument()
                                    reloadDocumentsTable();
                                }}
                                typeDocument={typeDocumentSelected}
                                workerId={Number(worker?.id)}
                                onCancel={hideCreateDocument}
                                onError={hideCreateDocument}
                            />
                        </DefaultModal>
                    ) : null}

                    {showingHistoryDocument ? (
                        <DefaultModal
                            show={showingHistoryDocument}
                            handleClose={hideHistoryDocument}
                            title={`Historial de documentos de ${
                                documentTypes?.find(
                                    (workerDoc: any) =>
                                        workerDoc.id === typeDocumentSelected
                                ).document_name
                            }`}
                            backdrop={true}
                            size={'lg'}
                            showFooter={false}
                        >
                            <WorkerDocumentHistory
                                typeDocument={typeDocumentSelected}
                                workerId={Number(worker?.id)}
                                onCancel={hideHistoryDocument}
                                onError={hideHistoryDocument}
                            />
                        </DefaultModal>
                    ) : null}
                </Tab>
                
                <Tab eventKey="bank-accounts-data" title="Cuentas Bancarias">
                    <div className="h3 mt-0 mb-2 card-title">
                        Cuentas Bancarias
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="col my-3">
                                <ButtonCreate
                                    callbackCreate={showCreateBankAccount}
                                    title={'Nueva Cuenta Bancaria'}
                                />
                            </div>
                        </div>
                    </div>
                    <BankAccountsDataTable 
                        data={bankAccounts}
                        progressPending={fetchingGetBankAccountByWorker}
                        destroy={destroy}
                        edit={showEditBankAccount}
                    />

                    {showingCreateBankAccount ? (
                        <DefaultModal
                            show={showingCreateBankAccount}
                            handleClose={hideCreateBankAccount}
                            title="Agregar Cuenta Bancaria"
                            backdrop={true}
                            showFooter={false}
                        >
                            <WorkerBankAccountCreate
                                onSaved={() => {
                                    hideCreateBankAccount()
                                    reloadBankAccountsTable();
                                }}
                                workerId={Number(worker?.id)}
                                onCancel={hideCreateBankAccount}
                                onError={hideCreateBankAccount}
                            />
                        </DefaultModal>
                    ) : null}

                    {showingEditBankAccount ? (
                        <DefaultModal
                            show={showingEditBankAccount}
                            handleClose={hideEditBankAccount}
                            title="Modificar Cuenta Bancaria"
                            backdrop={true}
                            showFooter={false}
                        >
                            <WorkerBankAccountEdit
                                onSaved={() => {
                                    hideEditBankAccount()
                                    reloadBankAccountsTable();
                                }}
                                bankAccountId={bankAccountId}
                                onCancel={hideEditBankAccount}
                                onError={hideEditBankAccount}
                            />
                        </DefaultModal>
                    ) : null}
                </Tab>
            </Tabs>
        </div>
    )
}

export default DocumentAndBankAccounts
