import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { AITollFilters } from '../../models/AITolls';

const useAITolls = () => {
    const { doGet } = useFetch();

    const [fetchingCountPendingTolls, setFetchingCountPendingTolls] = useState(false);
    const [fetchingPendingTolls, setFetchingPendingTolls] = useState(false);
    const [fetchingAllPendingTolls, setFetchingAllPendingTolls] = useState(false);

    const getCountPendingTollExpenses = (
        filter: AITollFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.AI_TOLLS.COUNT_PENDING_TOLL_EXPENSES}?${queryString}`;

        doGet({
            ...events,
            url,
            setFetching: setFetchingCountPendingTolls,
        });
    };

    const getPendingTollExpenses = (
        filter: AITollFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.AI_TOLLS.PENDING_TOLL_EXPENSES}?${queryString}`;

        doGet({
            ...events,
            url,
            setFetching: setFetchingPendingTolls,
        });
    };

    const getAllPendingTollExpenses = (
        filter: AITollFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.AI_TOLLS.ALL_PENDING_TOLLS}?${queryString}`;

        doGet({
            ...events,
            url,
            setFetching: setFetchingAllPendingTolls,
        });
    };

    return {
        fetchingCountPendingTolls,
        fetchingPendingTolls,
        fetchingAllPendingTolls,
        getCountPendingTollExpenses,
        getPendingTollExpenses,
        getAllPendingTollExpenses,
    };
};

export default useAITolls;
