import React from 'react';
import { AITollFilters } from '../../../../app/models/AITolls';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

interface Props {
    filter: AITollFilters;
    setFilter: (filter: AITollFilters) => void;
    onSearch: () => void;
}

const AIFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="row">
            <div className="row">
                {/* Filtro por ID */}
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="id">
                            ID
                        </label>
                        <input
                            title="ID"
                            type="number"
                            id="id"
                            name="id"
                            className="form-control form-control-sm"
                            value={filter.id || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                {/* Filtro por Orden de Transporte */}
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="transport_order">
                            Orden de Transporte
                        </label>
                        <input
                            title="Orden de Transporte"
                            type="text"
                            id="transport_order"
                            name="transport_order"
                            className="form-control form-control-sm"
                            value={filter.transport_order || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                {/* Filtro por Fecha Desde */}
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="start_date">
                            Fecha Desde
                        </label>
                        <input
                            title="Fecha Desde"
                            type="date"
                            id="start_date"
                            name="start_date"
                            className="form-control form-control-sm"
                            value={filter.start_date || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                {/* Filtro por Fecha Hasta */}
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="end_date">
                            Fecha Hasta
                        </label>
                        <input
                            title="Fecha Hasta"
                            type="date"
                            id="end_date"
                            name="end_date"
                            className="form-control form-control-sm"
                            value={filter.end_date || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                {/* Botón de Búsqueda */}
                <div className="col-md-auto">
                    <div style={{ marginTop: '22px' }}>
                        <ButtonSearchFilter callbackSearch={onSearch} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AIFilter;
