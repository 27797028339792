import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { Tab, Tabs } from 'react-bootstrap';
import WorkersForm from '../components/forms/WorkersForm';
import { toast } from 'react-toastify';
import { Society } from '../../../app/models/Society';
import { User } from '../../../app/models/User';
import { Provider } from '../../../app/models/Provider';
import { Worker } from '../../../app/models/Workers';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import LazyLoading from '../../../components/LazyLoading';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import useWorkerService from '../../../app/services/hooks/useWorkerService';
import { Afp } from '../../../app/models/Afp';
import { Provision } from '../../../app/models/Provision';
import { Bank } from '../../../app/models/Bank';
import { BankAccountType } from '../../../app/models/BankAccountType';
import WorkersCalendar from './WorkersCalendar';
import CalendarRequestContainer from './CalendarRequestContainer';
import { DocumentSet } from '../../../app/models/DocumentSet';
import DocumentAndBankAccounts from '../components/DocumentAndBankAccounts';

type RouterParams = {
    id: string;
};

const WorkersEdit = () => {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { showLoading, hideLoading } = useContext(AppContext);
    const { id } = useParams<RouterParams>();
    const [name, setName] = useState('');
    const [afps, setAfps] = useState<Afp[]>([]);
    const [provisions, setProvisions] = useState<Provision[]>([]);
    const [banks, setBanks] = useState<Bank[]>([]);
    const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([]);
    const [accountReasons, setAccountReasons] = useState<any[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [documentSets, setDocumentSets] = useState<DocumentSet[]>([]);
    const [documentSetsToFilter, setDocumentSetsToFilter] = useState<DocumentSet[]>([]);

    const [worker, setWorker] = useState<Worker>({
        ['is_internal']: undefined,
        is_active: true
    });
    const [errorFields, setErrorFields] = useState<any>();

    const defaultActiveKey = queryParams.get('defaultActiveKey');
    const isCarrier = queryParams.get('isCarrier');

    const { editWorker, fetchingEditWorker, updateWorker, fetchingUpdateWorker } =
        useWorkerService();

    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    useEffect(() => {
        const workerUser = users.find((user) => user.id === worker.user_id);
        if (workerUser) {
            setName(`${workerUser.first_name} ${workerUser.last_name}`);
        } else {
            setName('');
        }
    }, [users, worker]);

    const edit = (id: number) => {
        editWorker(id, {
            onSuccess: (response: ServiceResponse) => {
                setName(
                    response.data.users.find(
                        (user: { id: any }) => user.id === response.data.worker.user_id
                    )?.name || ''
                );
                setSocieties(response.data.societies);
                setUsers(response.data.users);
                setProviders(response.data.providers);
                setProvisions(response.data.provisions);
                setBankAccountTypes(response.data.account_type);
                setAccountReasons(response.data.bank_account_reasons);
                setAfps(response.data.afp);
                setBanks(response.data.banks);
                setDocumentSets(response.data.document_sets);
                setDocumentSetsToFilter(response.data.worker.document_sets);
                const _worker = response.data.worker;
                if (response.data.worker.document_sets) {
                    _worker.document_sets = response.data.worker.document_sets.map(
                        (docSet: any) => {
                            return {
                                value: docSet.id,
                                label: docSet.name
                            };
                        }
                    );
                }
                if (response.data.bank_accounts) {
                    _worker.bank_accounts = response.data.bank_accounts;
                }
                if (response.data.worker.society_id) {
                    _worker.is_internal = true;
                } else if (response.data.worker.provider_id) {
                    _worker.is_internal = false;
                }
                setWorker(_worker);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const update = () => {
        if (worker) {
            if (showLoading) showLoading('loading', 'Actualizando conductor...');
            updateWorker(Number(worker?.id), worker, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    setErrorFields(undefined);
                    edit(parseInt(id));
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Trabajadores',
            url: '/workers',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/workers/' + id + '/edit',
            isActive: true
        }
    ];

    if (fetchingEditWorker) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    
    return (
        <>
            <Breadcrumbs pageSection={`Editar - ${name}`} breadcrumbs={breadcrumbs} />

            {/* Tabs */}

            <Tabs defaultActiveKey={defaultActiveKey!} id="quote-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-md-6">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                {worker && (
                                    <WorkersForm
                                        workerForm={worker}
                                        setWorkerForm={setWorker}
                                        users={users}
                                        societies={societies}
                                        providers={providers}
                                        errorFields={errorFields}
                                        afps={afps}
                                        provisions={provisions}
                                        documentSets={documentSets}
                                        isCreate={false}
                                        isEdit={true}
                                        isCarrier={isCarrier == 'true' ? true : false}
                                    />
                                )}
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateWorker}
                                        />
                                    </div>
                                </div>
                            </DefaultCard>
                        </div>
                        <>
                            {
                                worker && (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <DefaultCard>
                                                <DocumentAndBankAccounts
                                                    worker={worker}
                                                    documentSets={documentSetsToFilter}

                                                />
                                            </DefaultCard>
                                        </div>
                                    </div>
                                )
                            }                            
                        </>
                    </div>
                </Tab>

                <Tab eventKey="calendar" title="Calendario">
                    <WorkersCalendar worker={worker} />
                </Tab>
                <Tab eventKey="calendar-requests" title="Solicitudes">
                    <CalendarRequestContainer />
                </Tab>
            </Tabs>
        </>
    );
};

export default WorkersEdit;
