import React from 'react';
import ButtonSearchFilter from './buttons/ButtonSearchFilter';
import { DocumentSet } from '../app/models/DocumentSet';
import ButtonTableRepeat from './buttons/ButtonTableDownloadFile';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
    onSearch: () => void;
    documentSets: DocumentSet[];
    getDocumentSet?: (documentSetId: number) => void;
};

const DocumentFilter = ({ filter, setFilter, onSearch, documentSets,getDocumentSet }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="col-md-auto mb-3">
                <label className="font-size-10 mb-1" htmlFor="document_name">
                    Documento
                </label>
                <input
                    title="Nombre"
                    type="text"
                    id="document_name"
                    name="document_name"
                    className="form-control form-control-sm"
                    value={filter.document_name}
                    onChange={handlerInputFilter}
                />
            </div>
            <div className="col-md-auto mb-3">
                <label className="font-size-10 mb-1" htmlFor="document_set">
                    Set de documentos
                </label>
                <select
                    name="document_set"
                    id="document_set"
                    className="form-control form-select-sm"
                    value={filter.document_set ?? ''}
                    onChange={handlerSelectFilter}
                >
                    <option value="">Selecciona un set</option>
                    {documentSets.map((set) => (
                        <option key={set.id} value={set.id}>
                            {set.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="col-md-auto mb-3 d-flex align-items-end">
                <ButtonSearchFilter callbackSearch={onSearch} />
            </div>


            <div className="col-md-auto mb-3 d-flex align-items-end">
            <button
                type="button"
                className="btn btn-success btn-sm"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                    getDocumentSet && getDocumentSet(parseInt(filter.document_set || '0')); 
   
                }}
            >
                <i className="bi bi-download"></i> Descargar Set documentos
            </button>
            </div>
        </div>
    );
};

export default DocumentFilter;
